import { getFunctions, httpsCallable } from 'firebase/functions'
import { where, orderBy } from 'firebase/firestore'
import { PROFILE_TYPE, DATABASE } from '../../constants/Constant'
import axios from 'utils/axios'
import appUtil from '../../../src/views/app-views/myApps/appUtil'
import { compareVersions, validate } from 'compare-versions'
import { getMultipleDocument } from './firebaseCliDaoMethods'

const saveOrUpdateThirdPartyApp = (
  appData,
  tenantId,
  initialProfileID = '',
  isEdit = false,
  isAddVersion = false
) => {
  return new Promise(function (resolve, reject) {
    var data = { ...appData }
    data['userId'] = tenantId
    data['hostingURL'] =
      appData.hostingLocation == 'Google Drive'
        ? appData.googleDriveFileLink
        : appData.specifiedServer
    // let urlSplitArr = data['hostingURL'].split(".apk")[0].split("/")
    // data['fileName'] = `${urlSplitArr[urlSplitArr.length-1]}.apk`
    data['fileName'] = appData.fileName || ''
    data['initialProfileID'] = initialProfileID
    data['isEdit'] = isEdit
    data['isAddVersion'] = isAddVersion

    console.log(' data : ==> ', data)
    axios
      .post('saveThirdPartyApp', data)
      .then((res) => {
        console.log(res)
        resolve(res)
      })
      .catch((err) => {
        console.log('Error while saving my collection app: ', err.response)
        let errData = err.response
          ? err.response
          : { status: 'error', error: 'Error while saving my collection app' }
        resolve(errData)
      })
  })
}

const getThirdPartyApps = async (tenantId) => {
  let myCollectionAppsList = []

  try {
    let colPath = `/${DATABASE.TENANT_COL}/${tenantId}/${DATABASE.DEVICE_APPS}`
    let conditions = [where('type', '==', PROFILE_TYPE.THIRD_PARTY_APPS), where('status', '==', 'Success'), orderBy('updatedDateTime', 'desc')]
    let myCollectionAppsDocs = await getMultipleDocument(colPath, conditions)

    for (let myCollectionAppDoc of myCollectionAppsDocs.docs) {
      let myAppsData = myCollectionAppDoc.data()
      let temp = { ...myAppsData }
      temp.id = myCollectionAppDoc.id
      temp.appVersion = myAppsData.appVersion.sort((a, b) => {
        return !validate(a) ? 1 : !validate(b) ? -1 : compareVersions(b, a)
      })
      myCollectionAppsList.push(temp)
    }

    console.log(' getting the apps : ', myCollectionAppsList)
    return myCollectionAppsList
  } catch (err) {
    console.log('Error while fetching my collection apps: ' + err)
    return myCollectionAppsList
  }
}

const getThirdPartyAppDetailsByPackageName = async (tenantId, packageName) => {
  let myCollectionAppsList = []

  try {
    let conditions = [where('type', '==', PROFILE_TYPE.THIRD_PARTY_APPS), where('status', '==', 'Success'), where('appPackage', '==', packageName)]
    let colPath = `/${DATABASE.TENANT_COL}/${tenantId}/${DATABASE.DEVICE_APPS}`
    let myCollectionAppsDocs = await getMultipleDocument(colPath, conditions)

    for (let myCollectionAppDoc of myCollectionAppsDocs.docs) {
      let myAppsData = myCollectionAppDoc.data()
      let temp = { ...myAppsData }
      temp.id = myCollectionAppDoc.id
      myCollectionAppsList.push(temp)
    }

    console.log(' getting the apps : ', myCollectionAppsList)
    return myCollectionAppsList[0]
  } catch (err) {
    console.log('Error while fetching my collection apps: ' + err)
    return myCollectionAppsList
  }
}

const deleteThirdPartyApp = async (appPackage, id, appVersion, versionCode, tenantID, category) => {
  return new Promise(function (resolve, reject) {
    let data = {}
    data['userId'] = tenantID
    data['packageName'] = appPackage
    data['appId'] = id
    data['appVersion'] = appVersion
    data['versionCode'] = versionCode
    data['category'] = category
    axios
      .post('deleteThirdPartyApp', data)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        console.log('Error while deleting the my collection app: ', err.response)
        let errData = err.response
          ? err.response
          : { status: 'error', error: 'Error while deleting the my collection app' }
        resolve(errData)
      })
  })
}

const getGoogleDriveAuthUrl = async (tenantId) => {
  try {
    let data = {}
    data['userId'] = tenantId

    let authUrlFunc = httpsCallable(getFunctions(),'getGoogleDriveAuthUrl')
    let result = await authUrlFunc(data)
    return result
  } catch (err) {
    console.error('Error while getting auth url', err)
    return { success: false }
  }
}

const getGoogleDriveAccessToken = async (authCode, tenantId) => {
  try {
    let data = {}
    data['userId'] = tenantId
    data['code'] = authCode

    let accessTokenFunc = httpsCallable(getFunctions(),'getGoogleDriveAccessTokenByCode')
    let result = await accessTokenFunc(data)
    return result
  } catch (err) {
    console.error('Error while getting auth url', err)
  }
}

const getAppApkDetails = async (
  tenantId,
  mediaLink,
  appId,
  fileName = '',
  packageName = '',
  appVersion = '',
  initialProfileID = '',
  isEdit = false,
  isAddVersion = false
) => {
  try {
    let data = {}
    data['userId'] = tenantId
    data['item'] = mediaLink
    data['profileId'] = appId
    data['fileName'] = fileName
    data['packageName'] = packageName
    data['appVersion'] = appVersion
    data['initialProfileID'] = initialProfileID
    data['isEdit'] = isEdit
    data['isAddVersion'] = isAddVersion

    let extractApkFunc = httpsCallable(getFunctions(),'extractApk')
    let result = await extractApkFunc(data)
    return result
  } catch (err) {
    console.error('Error while getting the apk details', JSON.stringify(err))
    return { status: 'error' }
  }
}

const validateAppNameFromDB = (name, tenantId, profileId) => {
  const dbCollectionApps = `/${DATABASE.TENANT_COL}/${tenantId}/${DATABASE.DEVICE_APPS}`
  return new Promise(function (resolve, reject) {
    let conditions = [where('type', '==', PROFILE_TYPE.THIRD_PARTY_APPS), where('status', '==', 'Success'), where('appName', '==', name)]
    if (!profileId) {
      getMultipleDocument(dbCollectionApps, conditions)
        .then((doc) => {
          if (!doc.empty) {
            resolve(true)
          } else {
            resolve(false)
          }
        })
    } else {
      getMultipleDocument(dbCollectionApps, conditions)
        .then((doc) => {
          if (!doc.empty) {
            doc.forEach((element) => {
              if (element.id === profileId.split('_')[0]) {
                resolve(false)
              } else {
                resolve(true)
              }
            })
          } else {
            resolve(false)
          }
        })
    }
  })
}

const validatePackageNameFromDB = (packageName, tenantId, profileId) => {
  const dbCollectionApps = `/${DATABASE.TENANT_COL}/${tenantId}/${DATABASE.DEVICE_APPS}`
  return new Promise(function (resolve, reject) {
    let conditions = [where('type', '==', PROFILE_TYPE.THIRD_PARTY_APPS), where('status', '==', 'Success'), where('appPackage', '==', packageName)]

    if (!profileId) {
      getMultipleDocument(dbCollectionApps, conditions)
        .then((doc) => {
          if (!doc.empty) {
            resolve(true)
          } else {
            resolve(false)
          }
        })
    } else {
      getMultipleDocument(dbCollectionApps, conditions)
        .then((doc) => {
          if (!doc.empty) {
            doc.forEach((element) => {
              if (element.id === profileId.split('_')[0]) {
                resolve(false)
              } else {
                resolve(true)
              }
            })
          } else {
            resolve(false)
          }
        })
    }
  })
}

const deleteAppSetup = async (
  deploymentId,
  profileId,
  tenantID,
  appVersion,
  packageName,
  category,
  uploadFileType
) => {
  try {
    let data = {}
    data['userId'] = tenantID
    data['deploymentId'] = deploymentId
    data['profileId'] = profileId
    data['appVersion'] = appVersion
    data['packageName'] = packageName
    data['category'] = category
    data['uploadFileType'] = uploadFileType

    let addMessage = httpsCallable(getFunctions(),'deleteAppSetUp')
    let result = await addMessage(data)
    return result
  } catch (err) {
    console.error('Error while deleting the app setup record', err)
  }
}

const deleteSelectedMyCollectionApp = async (tenantID, selectedDocs) => {
  return new Promise(function (resolve, reject) {
    let data = {}
    data['userId'] = tenantID
    data['apps'] = selectedDocs
    axios
      .post('deleteBulkThirdPartyApp', data)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        console.log('Error while deleting the selected collection app record: ', err.response)
        let errData = err.response
          ? err.response
          : { status: 'error', error: 'Error while deleting the selected collection app record' }
        resolve(errData)
      })
  })
}
const deleteSelectedMySetupprofile = async (tenantID, selectedappProfiles) => {
  try {
    let data = {}
    data['userId'] = tenantID
    data['appProfiles'] = selectedappProfiles
    let addMessage = httpsCallable(getFunctions(),'deleteBulkSetupProfiles')
    let result = await addMessage(data)
    return result
  } catch (err) {
    console.error('Error while deleting the selected setup app profiles record', err)
  }
}

const deleteEKDDesignprofile = async (tenantID, selecteddesignProfile)=>{
  const results = [];
  console.log('deleteEKDDesignprofile, selecteddesignProfile: ', selecteddesignProfile)
  for (const profile of selecteddesignProfile) {
    try {
      let data = {}
      data['userId'] = tenantID
      data['id'] = profile.id;
      let addMessage = httpsCallable(getFunctions(),'deleteDesign')
      let result = await addMessage(data)
      results.push(result);
    } catch (error) {
      console.error('Error while deleting the selected EKD design profiles record', error)
    }
  }
  return results
}

const updateAppNameInDb = async (userId, profileId, appPackage, appName, oldAppName) => {
  return new Promise((resolve) => {
    let updatedAppName = httpsCallable(getFunctions(),'updateAppName')
    updatedAppName({ userId, profileId, appPackage, appName, oldAppName })
      .then((res) => {
        return resolve(res)
      })
      .catch((e) => {
        console.log('Error while updating app Name : ', e)
        return resolve(e)
      })
  })
}

const getGoogleDriveData = (tenantId, userType) => {
  return axios
    .post('googleAccessToken', { userID: tenantId, userType: userType })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return err
    })
}

const logoutGoogleDrive = (tenantId) => {
  return axios
    .post('googleLogout', { userID: tenantId })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return err
    })
}

const getDataForMyCollection = (id, tenantId, appVersion) => {
  return new Promise(function (resolve, reject) {
    var data = {}
    data['userId'] = tenantId
    data['id'] = id
    data['appVersion'] = appVersion

    console.log(' data : ==> ', data)
    axios
      .post('getDataForMyCollection', data)
      .then((res) => {
        console.log(res)
        resolve(res)
      })
      .catch((err) => {
        console.log('Error in getDataForMyCollection: ', err.response)
        let errData = err.response
          ? err.response
          : { status: 'error', error: 'Error in getDataForMyCollection' }
        resolve(errData)
      })
  })
}

const getDataForThirdPartyAppsData = (tenantId) => {
  return new Promise(function (resolve, reject) {
    var data = {}
    data['userId'] = tenantId
    console.log(' data : ==> ', data)
    axios
      .post('getDataForThirdPartyAppsData', data)
      .then((res) => {
        console.log(res)
        resolve(res)
      })
      .catch((err) => {
        console.log('Error in getDataForThirdPartyAppsData: ', err.response)
        let errData = err.response
          ? err.response
          : { status: 'error', error: 'Error in getDataForThirdPartyAppsData' }
        resolve(errData)
      })
  })
}

const dismissGoogleWarningBanner = async (tenantId) => {
  return new Promise(function (resolve, reject) {
    var data = { hideGoogleDriveWarningBanner: true, userId: tenantId }
    axios
      .post('updateTenantDetails', data)
      .then((res) => {
        console.log(res)
        resolve(res)
      })
      .catch((err) => {
        console.log('Error in dismissGoogleWarningBanner: ', err.response.statusText)
        resolve({
          success: 'failed',
          message: `${err.response.statusText} while dismissing the warning banner.`,
        })
      })
  })
}

const dismissDashboardWarningBanner = async (tenantId, version) => {
  return new Promise(function (resolve, reject) {
    var data = { whatsNewBannerVersion: version, userId: tenantId }
    axios
      .post('updateTenantDetails', data)
      .then((res) => {
        console.log(res)
        resolve(res)
      })
      .catch((err) => {
        console.log('Error in dismissDashboardWarningBanner: ', err.response.statusText)
        resolve({
          success: 'failed',
          message: `${err.response.statusText} while dismissing Dashboard Warning Banner.`,
        })
      })
  })
}

const createDriveURL = (tenantId, webContentLink, source) => {
  return axios
    .post('createDownloadURL', { userID: tenantId, webContentLink: webContentLink, source: source })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return err
    })
}

export {
  saveOrUpdateThirdPartyApp,
  getThirdPartyApps,
  deleteThirdPartyApp,
  getGoogleDriveAuthUrl,
  getGoogleDriveAccessToken,
  getAppApkDetails,
  validateAppNameFromDB,
  validatePackageNameFromDB,
  deleteAppSetup,
  deleteSelectedMyCollectionApp,
  deleteSelectedMySetupprofile,
  deleteEKDDesignprofile,
  updateAppNameInDb,
  getThirdPartyAppDetailsByPackageName,
  getGoogleDriveData,
  logoutGoogleDrive,
  getDataForMyCollection,
  getDataForThirdPartyAppsData,
  dismissGoogleWarningBanner,
  dismissDashboardWarningBanner,
  createDriveURL
}
